import { url } from "inspector"


export const getMenuLeaf = async (_path?:string, instance?:string) =>{
    const path = _path.replace(/^\//,"").replace(/%/g,"%25")

    try{
        const res = await tFetch(`/api/v3/get-menu?pathEncoded=${encodeURIComponent(path || '/')}&instance=${instance||''}`) 

        if(res.status == 200 && res.payload){
            return {
                ...res.payload,
                display: res.payload.display || res.payload.Display,
                children: (res.payload.children || res.payload.Children||[]).map((c)=>{
                    return {
                        ...c,
                        display: c.display || c.Display
                    }
                })
            }
        }else{
            console.log('getMenuLeaf err 01', res)
            return {
                id: '',
                name: '',
                display: '',
                children:[]
            }
        }
    }catch(e){
        console.log('getMenuLeaf err', e)
        return undefined
    }
}


const rootCache = {value:[] as  any}
export const getMenuRoots = async () => {
    return [
        {
            display:"酒店", 
            url:"/section/jiudian",
            startShows:true,
            logo:"https://img.js.design/assets/element/js_1vUKQOHLOrA/image/3d01e1547bab39052a26e82fff725f6573d4298f.png",
            children:[
                {
                    display:"酒店报告",
                    children:[
                        {
                            display:"月度经营分析报告",
                            url:"/jiudian/ydjyfx"
                        },
                        {
                            display:"季度经营分析报告",
                            url:"/jiudian/jdjyfx"
                        },
                        {
                            display:"年度经营分析报告",
                            url:"/jiudian/ndjyfx"
                        },
                    ]
                },
                {
                    display:"数据填报",
                    children:[
                        {
                            display:"月度数据填报",
                            url:"/jiudian/ydsjtb"
                        },
                        {
                            display:"基础数据填报",
                            url:"/jiudian/jcsjtb"
                        }
                    ]
                },
            ]
        },
        {
            display:"城市", 
            url:"/section/chengshi",
            startShows:true,
            logo:"https://img.js.design/assets/element/js_1vUKQOHLOrA/image/8c1600926fc90678798ec19b8fa041ecb14f74c1.png",
            children:[
                {
                    display:"城市报告",
                    children:[
                        {
                            display:"月度经营分析报告",
                            url:"/chengshi/ydjyfx"
                        },
                        {
                            display:"季度经营分析报告",
                            url:"/chengshi/jdjyfx"
                        },
                        {
                            display:"年度经营分析报告",
                            url:"/chengshi/ndjyfx"
                        },
                        {
                            display:"年度经营预测报告",
                            url:"/chengshi/ndjyyc"
                        },
                        {
                            display:"评估与展望",
                            url:"/chengshi/pgyzw"
                        },
                    ]
                },
            ]
        },
        {
            display:"省份", 
            url:"/section/shengfen",
            startShows:true,
            logo:"https://img.js.design/assets/img/6715f2b90c8862f00f54f4a8.png#52af732740ae0388d5ee34271ed9c5e9",
            children:[
                {
                    display:"省级报告",
                    children:[
                        {
                            display:"月度经营分析报告",
                            url:"/shengfen/ydjyfx"
                        },
                        {
                            display:"季度经营分析报告",
                            url:"/shengfen/jdjyfx"
                        },
                        {
                            display:"年度经营分析报告",
                            url:"/shengfen/ndjyfx"
                        },
                    ]
                },
            ]
        },
        {
            display:"行业", 
            url:"/hangye/wlbtjbg",
            startShows:true,
            logo:"https://img.js.design/assets/img/6715f2b90c8862f00f54f4a8.png#52af732740ae0388d5ee34271ed9c5e9",
            children:[
                {
                    display:"行业报告",
                    children:[
                        {
                            display:"文旅部统计报告",
                            url:"/hangye/wlbtjbg"
                        },
                        {
                            display:"酒管集团季度财报",
                            url:"/hangye/jgjtjdcb"
                        },
                    ]
                },
                {
                    display:"华美云报告",
                    children:[
                        {
                            display:"华美年报",
                            url:"/hangye/hmnb"
                        },
                    ]
                },
            ]
        },
        {
            display:"协会", 
            url:"/section/xiehui",
            startShows:true,
            logo:"https://img.js.design/assets/element/js_1vUKQOHLOrA/image/cea3ca63cd1fe8a021967fa64a4fbed6c2fc1cab.png",
            children:[
                {
                    display:"数据管理",
                    children:[
                        {
                            display:"月度数据审核",
                            url:"/xiehui/ydsjsh"
                        },
                        // {
                        //     display:"月度数据提醒",
                        //     url:"/xiehui/ydsjtx"
                        // },
                    ]
                },
                {
                    display:"",
                    children:[
                        {
                            display:"报告生成",
                            url:"/xiehui/bgsc",
                            children:[]
                        },
                    ]
                },
                {
                    display:"",
                    children:[
                        {
                            display:"报告预览",
                            url:"/xiehui/bgyl",
                            children:[]
                        },
                    ]
                }
            ]
        },
        {
            display:"系统", 
            url:"/section/xitong",
            startShows:false,
            logo:"",
            children:[
                {
                    display:"",
                    children:[
                        {
                            display:"用户管理",
                            url:"/xitong/yhgl"
                        },
                    ]
                },
                {
                    display:"",
                    children:[
                        {
                            display:"系统设置",
                            url:"/xitong/xtsz"
                        },
                    ]
                },
            ]
        },
    ]
}